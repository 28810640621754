export function validatePassword(value) {
  const specialRegex = /[!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|._-]+/
  const capitalLetterRegex = /^(?=.*[A-Z]).*$/
  const numericRegex = /[0-9]/

  return (
    value.length >= 6 &&
    specialRegex.test(value) &&
    numericRegex.test(value) &&
    capitalLetterRegex.test(value)
  )
}
