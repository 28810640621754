import { Platform } from 'react-native'

const awsConfig = {
  aws_project_region: process.env.REACT_APP_AWS_REGION,
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cognito_identity_pool_id: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_REGION,
  aws_user_pools_id: process.env.REACT_APP_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_USER_POOLS_WEB_CLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_COGNITO_DOMAIN,
    redirectSignIn:
      Platform.OS === 'web'
        ? `${window.location.origin}/linkedin-loader`
        : 'appHero://',
    redirectSignOut:
      Platform.OS === 'web' ? `${window.location.origin}/login` : 'appHero://',
    responseType: 'code',
    scope: [
      'email',
      'openid',
      'phone',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
  },
  API: {
    endpoints: [
      {
        name: 'graphql',
        endpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
        region: process.env.REACT_APP_AWS_REGION,
        service: 'appsync',
      },
    ],
  },
}

export default awsConfig
