import {
  View,
  StyleSheet,
  ImageBackground,
  ScrollView,
  useWindowDimensions,
  TouchableOpacity,
} from 'react-native'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@libs/theme'
import { Button, Loader, PasswordInput, Text } from '@libs/components'
import { useIsFocused } from '@react-navigation/native'
import { isWeb } from '@libs/utils/src/screenLayout'
import PasswordValidateBox from '@libs/components/src/PasswordInput/PasswordValidateBox'
import ModalComponent from '../../components/modal'
import { TitleHeader } from '../../components/headerTitle'
import { findPaddingHorizontal } from '../../utils/findPaddingHorizontal'

const DesktopView = ({
  ctaEnable,
  errorMessage,
  handlePasswordFieldChange,
  changePasswordDetails,
  successPopup,
  setSuccessPopup,
  isProfileUpdating,
  handleSave,
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const web = isWeb(width)
  const [passNumValidate, setPassNumValidate] = useState(false)
  const [passSpecialValidate, setPassSpecialValidate] = useState(false)
  const [passLenValidate, setPassLenValidate] = useState(false)
  const [, setShowValidateBox] = useState(false)
  const [validateError, setValidateError] = useState('')
  const [passLetterValidate, setPassLetterValidate] = useState(false)
  const isFocused = useIsFocused()
  const onPassAllValid = () => {}

  const PassWordValidatorData = [
    {
      id: 1,
      checkLabel: t('PASSWORD_VALIDATION.VALIDATION_1'),
      checkStatus: passLenValidate,
    },
    {
      id: 2,
      checkLabel: t('PASSWORD_VALIDATION.VALIDATION_2'),
      checkStatus: passLetterValidate,
    },
    {
      id: 3,
      checkLabel: t('PASSWORD_VALIDATION.VALIDATION_3'),
      checkStatus: passSpecialValidate,
    },
    {
      id: 4,
      checkLabel: t('PASSWORD_VALIDATION.VALIDATION_4'),
      checkStatus: passNumValidate,
    },
  ]

  useEffect(() => {
    setPassSpecialValidate(false)
    setPassLetterValidate(false)
    setPassLenValidate(false)
    setPassNumValidate(false)
    onPassAllValid(false)

    const specialRegex = new RegExp(
      // eslint-disable-next-line no-useless-escape
      /[!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|._-]+/,
    )
    const capitalLetterRegex = new RegExp(/^(?=.*[A-Z]).*$/)
    const numericRegex = new RegExp(/[0-9]/)

    if (specialRegex.test(changePasswordDetails.new_Password)) {
      setPassSpecialValidate(true)
    }

    if (capitalLetterRegex.test(changePasswordDetails.new_Password)) {
      setPassLetterValidate(true)
    }
    if (changePasswordDetails.new_Password.length >= 6) {
      setPassLenValidate(true)
    }

    if (numericRegex.test(changePasswordDetails.new_Password)) {
      setPassNumValidate(true)
    }
    if (
      changePasswordDetails.new_Password.length >= 6 &&
      specialRegex.test(changePasswordDetails.new_Password) &&
      capitalLetterRegex.test(changePasswordDetails.new_Password) &&
      numericRegex.test(changePasswordDetails.new_Password)
    ) {
      onPassAllValid(true)
    }
  }, [changePasswordDetails.new_Password])

  useEffect(() => {
    if (!isFocused) return
    if (isFocused) {
      setValidateError('')
      setShowValidateBox(true)
    }
  }, [isFocused])

  return (
    <ImageBackground
      style={{ flex: 1 }}
      imageStyle={{
        width: '60%',
        position: 'absolute',
        top: 0,
        left: '40%',
      }}
    >
      <ScrollView style={{ flex: 1, marginTop: 32 }}>
        <View style={{ paddingHorizontal: findPaddingHorizontal(width) }}>
          <TitleHeader title={t('PROFILE.CHANGE_PASSWORD')} />
        </View>
        <View style={styles.mainContainer(web)}>
          <View style={styles.profileContainer}>
            <View style={styles.formContainer}>
              <View style={styles.subContainer(web)}>
                <PasswordInput
                  value={changePasswordDetails.current_Password}
                  onChangeText={(text) =>
                    handlePasswordFieldChange('current_Password', text)
                  }
                  placeholder={t('TEXT_INPUT.CURRENT_PASSWORD')}
                  style={styles.textInput}
                />
                <View
                  style={[styles.divider, { marginTop: 10, marginBottom: 20 }]}
                />
                <PasswordInput
                  value={changePasswordDetails.new_Password}
                  onChangeText={(text) =>
                    handlePasswordFieldChange('new_Password', text)
                  }
                  // onPassAllValid={(val) =>
                  //   changePasswordDetails.new_Password.length > 0
                  //     ? setCTAEnable(val)
                  //     : setCTAEnable(true)
                  // }
                  // hasValidate
                  placeholder={t('TEXT_INPUT.NEW_PASSWORD')}
                  style={styles.textInput}
                />
                <PasswordInput
                  value={changePasswordDetails.repeat_Password}
                  onChangeText={(text) =>
                    handlePasswordFieldChange('repeat_Password', text)
                  }
                  placeholder={t('TEXT_INPUT.REPEAT_PASSWORD')}
                  style={[styles.textInput, { marginTop: 16 }]}
                />
                {changePasswordDetails.new_Password.length > 0 && (
                  <PasswordValidateBox
                    data={PassWordValidatorData}
                    errorLabel={validateError}
                    isFocused={!changePasswordDetails.new_Password?.length > 0}
                  />
                )}
                {errorMessage ? (
                  <Text variant="display3" color={colors.onAlert}>
                    {errorMessage}
                  </Text>
                ) : null}
              </View>
              {isProfileUpdating ? (
                <View style={{ marginBottom: 30 }}>
                  <Loader size={32} hasFlexValue={false} />
                </View>
              ) : (
                <Button
                  label={t('BUTTON.UPDATE_PASSWORD')}
                  labelStyle={styles.updateLabelStyle}
                  buttonStyle={styles.updateButtonStyle}
                  onPress={() => handleSave()}
                  disable={!ctaEnable}
                />
              )}
            </View>
          </View>
        </View>
      </ScrollView>
      <ModalComponent
        visible={successPopup.visible}
        title={successPopup.message}
        buttonLabel={t('BUTTON.OK')}
        handleCloseModal={() => {
          setSuccessPopup({
            visible: false,
            message: '',
          })
        }}
        handleButton={() => {
          setSuccessPopup({
            visible: false,
            message: '',
          })
        }}
        buttonStyle={{
          borderRadius: 4,
        }}
        titleVariant="display3"
        labelStyle={{ fontWeight: 700 }}
        buttonColor="#2563EB"
        labelColors="white"
      />
    </ImageBackground>
  )
}

const styles = StyleSheet.create({
  mainContainer: () => ({
    width: '100%',
    maxWidth: 895,
    maxHeight: '100%',
    marginHorizontal: 'auto',
    paddingVertical: 30,
  }),
  profileContainer: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    shadowColor: 'rgba(0, 15, 68, 0.15)',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 10,
    elevation: 5,
    borderRadius: 20,
    padding: 35,
  },

  profileHeader: {
    height: 70,
    flexDirection: 'row',
    position: 'relative',
    justifyContent: 'space-between',
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    alignItems: 'center',
  },

  subContainer: () => ({
    flexDirection: 'column',
  }),
  myProfileText: (colors) => ({
    fontWeight: 700,
    fontSize: 24,
    color: colors.primaryText,
    letterSpacing: 0.2,
  }),
  profileLeftHeader: {
    flexDirection: 'row',
    columnGap: 30,
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  divider: {
    borderWidth: 0.5,
    borderColor: '#E0E0E0',
    borderStyle: 'solid',
  },

  textInput: {
    backgroundColor: 'white',
    paddingVertical: 10,
    height: 56,
    borderRadius: 4,
    borderColor: '#E0E0E0',
  },
  updateButtonStyle: {
    alignItems: 'center',
    borderRadius: 4,
    justifyContent: 'center',
    backgroundColor: '#2563EB',
    marginLeft: 'auto',
    marginTop: 30,
    paddingHorizontal: 40,
    paddingVertical: 12,
  },
  updateLabelStyle: {
    color: 'white',
    flexDirection: 'row',
    fontSize: 14,
    fontWeight: '700',
    textTransform: 'uppercase',
    paddingHorizontal: 20,
  },
})

export default DesktopView
