import React, { useCallback, Suspense, useState } from 'react'
import { CustomAuth, ScreenLayout } from '@libs/utils'
import { useNavigation } from '@react-navigation/native'
import { Text } from 'react-native'
import { useTranslation } from 'react-i18next'
import DesktopView from './DesktopView'
import MobileView from './MobileView'
import { validatePassword } from '../../utils/passwordValidator'

const ChangePassword = () => {
  const [changePasswordDetails, setChangePasswordDetails] = useState({
    current_Password: '',
    new_Password: '',
    repeat_Password: '',
  })

  const [ctaEnable, setCTAEnable] = useState(false)
  const [isProfileUpdating, setIsProfileUpdating] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [successPopup, setSuccessPopup] = useState({
    visible: false,
    message: '',
  })
  const { t } = useTranslation()
  const navigation = useNavigation()

  const handlePasswordFieldChange = (field, selectedValue) => {
    if (
      changePasswordDetails.new_Password.length > 0 &&
      changePasswordDetails.current_Password.length > 0 &&
      changePasswordDetails.repeat_Password.length > 0 &&
      validatePassword(changePasswordDetails.new_Password)
    ) {
      setCTAEnable(true)
    }
    setErrorMessage('')
    setChangePasswordDetails((previousData) => ({
      ...previousData,
      [field]: selectedValue,
    }))
  }

  const handleSave = async () => {
    let passwordChangeResponse = { isSuccess: false }
    setIsProfileUpdating(true)
    if (Object.values(changePasswordDetails).some((item) => !!item)) {
      let emptyFieldsName = []
      Object.entries(changePasswordDetails).forEach(([key, value]) => {
        if (!value) emptyFieldsName.push(key)
      })
      if (changePasswordDetails.current_Password.length === 0) {
        setErrorMessage(`${t('TEXT_INPUT.CURRENT_PASSWORD')} can't be empty`)
      }
      if (
        changePasswordDetails.current_Password ===
        changePasswordDetails.new_Password
      ) {
        setErrorMessage(
          `${t('TEXT_INPUT.NEW_PASSWORD')} ${t(
            'ERROR_MESSAGE.NOT_BE_SAME',
          )} ${t('TEXT_INPUT.CURRENT_PASSWORD')} `,
        )
      } else if (!validatePassword(changePasswordDetails.new_Password)) {
        setErrorMessage(`Invalid Password`)
      } else if (
        changePasswordDetails.new_Password !==
        changePasswordDetails.repeat_Password
      ) {
        setErrorMessage(`${t('TEXT_INPUT.PASSWORD_NOT_MATCH')}`)
      } else {
        passwordChangeResponse = await CustomAuth.changePasswordAsync(
          changePasswordDetails.current_Password,
          changePasswordDetails.new_Password,
        )
        if (passwordChangeResponse?.message) {
          if (passwordChangeResponse?.message?.includes('password')) {
            setErrorMessage(
              `${t('ERROR_MESSAGE.INVALID')} ${t(
                'TEXT_INPUT.CURRENT_PASSWORD',
              )}`,
            )
          } else {
            setErrorMessage(passwordChangeResponse?.message)
          }
        } else {
          passwordChangeResponse = { isSuccess: true }
        }
      }
    }

    if (passwordChangeResponse?.isSuccess) {
      setSuccessPopup({
        visible: true,
        message: t('ALERT.PASSWORD_CHANGE_SUCCESS'),
      })
      setChangePasswordDetails({
        current_Password: '',
        new_Password: '',
        repeat_Password: '',
      })
    }
    setIsProfileUpdating(false)
  }

  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, MobileView, MobileView),
    [],
  )

  const viewProps = {
    ctaEnable,
    errorMessage,
    handlePasswordFieldChange,
    changePasswordDetails,
    successPopup,
    setSuccessPopup,
    isProfileUpdating,
    handleSave,
    setCTAEnable,
    navigation,
  }

  return (
    <Suspense fallback={<Text>Loading</Text>}>
      <LayoutView {...viewProps} />
    </Suspense>
  )
}

export default ChangePassword
