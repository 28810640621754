import { Auth } from 'aws-amplify'
import { CustomAuth, SecureStore } from '@libs/utils'

export function getScreenName() {
  const screenName = window.location.pathname
  return screenName
}

export const screenUseCaseMappings = {
  '/dashboard': 'DASHBOARD',
  '/my-application': 'MY_APPLICATION',
  '/action-required': 'ACTION_REQUIRED',
  '/view-application-details': 'APPLICATION_DETAILS',
  '/profile': 'ACCOUNT_INFORMATION',
  '/change-password': 'CHANGE_PASSWORD',
  '/notification': 'NOTIFICATION',
  '/privacy-policy': 'PRIVACY_POLICY',
  '/sign-up': 'SIGN_UP',
  '/set-password': 'SET_PASSWORD',
  '/otp': 'VERIFY_OTP_WHEN_SIGN_UP',
  '/forgot-password': 'FORGOT_PASSWORD',
  '/login': 'LOGIN',
}

export const authCall = async (url, options) => {
  try {
    let config = {}
    const tempConfig = await SecureStore.getItemAsync('config')
    const userData = await SecureStore.getItemAsync('userProfile')
    const userProfile = JSON.parse(userData)
    const gusApiUrl = process.env.REACT_APP_GUS_API_URL

    if (!tempConfig) {
      const response = await fetch(`${gusApiUrl}/config.json`)
      const result = await response.json()
      await SecureStore.setItemAsync('config', JSON.stringify(result))
      config = result?.config
    } else {
      config = JSON.parse(tempConfig)?.config
    }
    const requestOptions = {
      method: options?.method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-api-key': config.apiKey,
        Authorization: userProfile?.accessToken,
        'x-screen-name': screenUseCaseMappings[getScreenName()],
      },

      body: JSON.stringify(options?.payload),
    }

    let response
    if (userProfile?.accessToken) {
      response = await fetch(`${config.apiUrl}/${url}`, requestOptions)
    }

    console.log({ config })

    if (response?.status === 401) {
      // const newToken = await Auth.currentSession().then((res) => ({
      //   tokens: {
      //     idToken: res.idToken.jwtToken,
      //     accessToken: res.accessToken.jwtToken,
      //     refreshToken: res.refreshToken.token,
      //   },
      // }))
      // const newProfile = {
      //   ...userProfile,
      //   accessToken: newToken.tokens.accessToken,
      // }
      // const convertedResponse = JSON.stringify(newProfile)
      // await SecureStore.setItemAsync('userProfile', convertedResponse)
      // return await authCall(url, options)
      await CustomAuth.logOut()
      await SecureStore.clearAll()
      return window.location.replace('login')
    }

    if (!response?.ok) {
      return response
    }

    try {
      const data = await response.json()
      if (data) {
        return data
      }
    } catch (error) {
      return response
    }
  } catch (error) {
    console.log({ error })
    return error
  }
}

export const noAuthCall = async (url, options) => {
  try {
    let config = {}
    const tempConfig = await SecureStore.getItemAsync('config')
    const gusApiUrl = process.env.REACT_APP_GUS_API_URL
    if (!tempConfig) {
      const response = await fetch(`${gusApiUrl}/config.json`)
      const result = await response.json()
      await SecureStore.setItemAsync('config', JSON.stringify(result))
      config = result?.config
    } else {
      config = JSON.parse(tempConfig)?.config
    }
    const requestOptions = {
      method: options?.method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-screen-name': screenUseCaseMappings[getScreenName()],
      },

      body: JSON.stringify(options?.payload),
    }

    const response = await fetch(`${config.apiUrl}/${url}`, requestOptions)

    if (!response?.ok) {
      return response
    }

    try {
      const data = await response?.json()
      if (data) {
        return data
      }
    } catch (error) {
      return response
    }
  } catch (error) {
    console.log({ error })
    return error
  }
}

function convertToNoauthUrl(apiUrl) {
  const regex = /(https:\/\/[^\/]+)\//
  return apiUrl.replace(regex, '$1/unauth/')
}

export const unAuthThemeCall = async (url, options) => {
  try {
    let config = {}
    const tempConfig = await SecureStore.getItemAsync('config')
    const gusApiUrl = process.env.REACT_APP_GUS_API_URL
    if (!tempConfig) {
      const response = await fetch(`${gusApiUrl}/config.json`)
      const result = await response.json()
      await SecureStore.setItemAsync('config', JSON.stringify(result))
      config = result?.config
    } else {
      config = JSON.parse(tempConfig)?.config
    }
    const requestOptions = {
      method: options?.method || 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-screen-name': screenUseCaseMappings[getScreenName()],
      },
      body: JSON.stringify(options?.payload),
    }
    const response = await fetch(
      `${convertToNoauthUrl(config?.apiUrl)}/${url}`,
      requestOptions,
    )
    if (!response?.ok) {
      return response
    }

    try {
      const data = await response?.json()
      if (data) {
        return data
      }
    } catch (error) {
      return response
    }
  } catch (error) {
    console.log({ error })
    return error
  }
}

export const botAPICall = async (url, options) => {
  try {
    let config = {}
    const tempConfig = await SecureStore.getItemAsync('config')
    const gusApiUrl = window.location.origin
    if (!tempConfig) {
      const response = await fetch(`${gusApiUrl}/config.json`)
      const result = await response.json()
      await SecureStore.setItemAsync('config', JSON.stringify(result))
      config = result?.config
    } else {
      config = JSON.parse(tempConfig)?.config
    }
    const requestOptions = {
      method: options?.method,
      headers: {
        Authorization: `BotConnector ${config.rockyBotSecretKey}`,
      },
    }
    const response = await fetch(`${url}`, requestOptions)

    if (!response?.ok) {
      return response
    }

    try {
      const data = await response.json()
      if (data) {
        return data
      }
    } catch (error) {
      return response
    }
  } catch (error) {
    return error
  }
}

export const getCurrentLocation = () =>
  fetch('https://ipapi.co/json/')
    .then((res) => res.json())
    .catch(() => {})

export const getOpportunities = async (payload) =>
  authCall('opportunitiesbyemail', {
    method: 'POST',
    payload,
  })

export const getTasksByEmail = async (payload) =>
  authCall('tasksbyemail', {
    method: 'POST',
    payload,
  })

export const getOpportunitiesById = async (payload) =>
  authCall(`opportunities/${payload?.opportunityId}/${payload?.email}`, {
    method: 'GET',
  })

export const getDocumentsByOpportunityId = async (payload) =>
  authCall(`opportunityfilesbyopportunityid/${payload?.opportunityId}`, {
    method: 'GET',
  })

export const getAllPrograms = async (payload) =>
  authCall('programmes/list', {
    method: 'POST',
    payload,
  })

export const getProgramsDetailsById = async (payload) =>
  authCall(`programmes/${payload?.id}/${payload?.brand}`, {
    method: 'GET',
  })

export const getPricingBookDetail = async (payload) =>
  authCall(`pricebookentries/${payload?.id}/${payload?.brand}`, {
    method: 'GET',
  })
export const getAllInstitution = async (payload) =>
  authCall(`programmeInstitutions`, {
    method: 'POST',
    payload,
  })

export const getFilterData = async () =>
  authCall('programme/lookupdata', {
    method: 'GET',
  })

export const compareProgramsByIds = async (payload) => {
  const ids = payload?.ids
  const finalIds = ids?.map((id) => id).join(',')
  return authCall(`compareprogrammes?ids=${finalIds}`, {
    method: 'GET',
  })
}

export const getBotConnection = async (payload) =>
  botAPICall(`https://webchat.botframework.com/api/tokens`, {
    method: 'GET',
  })

export const getBasketItems = async (payload) =>
  authCall(`application/basket/${payload.email}`, {
    method: 'GET',
  })

export const updateBasketItem = async (payload) =>
  authCall('application/basket', {
    method: 'POST',
    payload,
  })

export const deleteBasketItem = async (payload) =>
  authCall(
    `application/basket?email=${payload.email}&externalId=${payload.externalId}`,
    {
      method: 'DELETE',
    },
  )

export const getCompareProgrammesId = async (payload) =>
  authCall(`programme/compare/basket/${payload.email}`, {
    method: 'GET',
  })

export const updateCompareProgrammesIds = async (payload) =>
  authCall('programme/compare/basket', {
    method: 'POST',
    payload,
  })

export const uploadFile = async (payload) =>
  authCall('oppotunityfiles/upload', {
    method: 'POST',
    payload,
  })

export const getDocumentType = async ({ brandName = '' }) =>
  authCall(`document/types/${brandName}`, {
    method: 'GET',
  })

export const getUserDetails = async (payload) =>
  authCall(`profile/${payload?.email}`, {
    method: 'GET',
  })

export const createUserDetails = async (payload) =>
  authCall(`profile`, {
    method: 'POST',
    payload,
  })
export const signedurl = async (payload) =>
  authCall(`oppotunityfile/signedurl`, {
    method: 'POST',
    payload,
  })

export const updateUserDetails = async (payload, email) =>
  authCall(`profile/${email}`, {
    method: 'PATCH',
    payload,
  })

export const getCountryList = async () =>
  noAuthCall(`lookup/country`, {
    method: 'GET',
  })

export const updateReadState = async (payload) => {
  authCall(`opportunity/updateComments`, {
    method: 'POST',
    payload,
  })
}

export const getActionRequiredItemsByOpportunityId = async (opportunityId) =>
  authCall(`taskbyopportunityid/${opportunityId}`, {
    method: 'GET',
  })

export const uploadOpportunityTaskFile = async (payload) =>
  authCall('uploadFile', {
    method: 'POST',
    payload,
  })

export const deleteOpportunityTaskFile = async (payload) =>
  authCall('deleteFile', {
    method: 'POST',
    payload,
  })

export const submitAllOpportunityTaskDocuments = async (payload) =>
  authCall('opportunityfiles/closetask', {
    method: 'POST',
    payload,
  })

export const previewOpportunityTaskDocument = async (objectKey) =>
  authCall(`opportunityfiles/generateSignedUrl?objectKey=${objectKey}`, {
    method: 'GET',
  })

export const downloadOpportunityTaskDocument = async (objectKey) =>
  authCall(
    `opportunityfiles/generateSignedUrl?objectKey=${objectKey}&toDownload=true`,
    {
      method: 'GET',
    },
  )

export const closeTask = async (payload) =>
  authCall('opportunityfiles/closetask', {
    method: 'POST',
    payload,
  })

export const fetchWebinarEvents = (payload) =>
  authCall('content/events', {
    method: 'POST',
    payload,
  })

export const fetchCMSContent = async (cmsPayload) => {
  const limit = 3
  const response = await authCall('content/filter', {
    method: 'POST',
    payload: { email: cmsPayload?.email },
  })

  const skip = (cmsPayload.page - 1) * limit
  const currentPageItems = response.results.slice(skip, skip + limit)
  const nextPage = cmsPayload.page + 1
  const hasMore = response.total > cmsPayload.page * limit
  return {
    data: currentPageItems,
    nextPage,
    hasMore,
  }
}

export const getStudentDetail = async (email) => {
  let config = {}
  const tempConfig = await SecureStore.getItemAsync('config')
  const gusApiUrl = window.location.origin
  if (!tempConfig) {
    const response = await fetch(`${gusApiUrl}/config.json`)
    const result = await response.json()
    await SecureStore.setItemAsync('config', JSON.stringify(result))
    config = result?.config
  } else {
    config = JSON.parse(tempConfig)?.config
  }

  try {
    const response = await fetch(
      `${config.studentDetailAPIBaseUrl}/studentdetail/${email}`,
      {
        method: 'GET',
      },
    )
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`)
    }

    const data = await response.json()
    return data
  } catch (error) {
    return error
  }
}

export const getBrandTheme = async ({ id }) =>
  unAuthThemeCall(`cobranddetail/${id}`)

export const getLookUpData = async (lookuptype, lookup) =>
  unAuthThemeCall(`lookup?lookuptype=${lookuptype}&lookup=${lookup}`)

export const createCloudWatchLog = async (payload) =>
  unAuthThemeCall('logger', { method: 'POST', payload })
