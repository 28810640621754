import { TouchableOpacity } from 'react-native'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@libs/theme'
import { Icon } from '@app-hero/native-icons'
import { useIsFocused } from '@react-navigation/native'
import TextInput from '../TextInput/TextInput'
import PasswordValidateBox from './PasswordValidateBox'

const PasswordInput = (props) => {
  const { colors } = useTheme()
  const {
    hasValidate = false,
    inputFieldStyle,
    onBlur = () => {},
    onChangeText,
    onFocus = () => {},
    onPassAllValid = () => {},
    placeholder = 'Password',
    placeholderColor = '',
    validationTextColor = '',
    value = '',
    iconColor,
    hasValidateOnly = false,
    iconSize,
  } = props

  const [secureTextEntry, setSecureTextEntry] = useState(true)
  const [passLetterValidate, setPassLetterValidate] = useState(false)
  const [passLenValidate, setPassLenValidate] = useState(false)
  const [passNumValidate, setPassNumValidate] = useState(false)
  const [passSpecialValidate, setPassSpecialValidate] = useState(false)
  const [showValidateBox, setShowValidateBox] = useState(false)
  const [validateError, setValidateError] = useState('')
  const isFocused = useIsFocused()
  const { t } = useTranslation()

  const PassWordValidatorData = [
    {
      id: 1,
      checkLabel: t('PASSWORD_VALIDATION.VALIDATION_1'),
      checkStatus: passLenValidate,
    },
    {
      id: 2,
      checkLabel: t('PASSWORD_VALIDATION.VALIDATION_2'),
      checkStatus: passLetterValidate,
    },
    {
      id: 3,
      checkLabel: t('PASSWORD_VALIDATION.VALIDATION_3'),
      checkStatus: passSpecialValidate,
    },
    {
      id: 4,
      checkLabel: t('PASSWORD_VALIDATION.VALIDATION_4'),
      checkStatus: passNumValidate,
    },
  ]

  useEffect(() => {
    setPassSpecialValidate(false)
    setPassLetterValidate(false)
    setPassLenValidate(false)
    setPassNumValidate(false)
    onPassAllValid(false)

    const specialRegex = new RegExp(
      // eslint-disable-next-line no-useless-escape
      /[!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|._-]+/,
    )
    const capitalLetterRegex = new RegExp(/^(?=.*[A-Z]).*$/)
    const numericRegex = new RegExp(/[0-9]/)

    if (specialRegex.test(value)) {
      setPassSpecialValidate(true)
    }

    if (capitalLetterRegex.test(value)) {
      setPassLetterValidate(true)
    }

    if (value.length >= 6) {
      setPassLenValidate(true)
    }
    if (numericRegex.test(value)) {
      setPassNumValidate(true)
    }
    if (
      value.length >= 6 &&
      specialRegex.test(value) &&
      capitalLetterRegex.test(value) &&
      numericRegex.test(value)
    ) {
      onPassAllValid(true)
    }
  }, [value])

  useEffect(() => {
    if (!isFocused) return
    if (isFocused && hasValidate) {
      setValidateError('')
      setShowValidateBox(true)
    }
  }, [isFocused])

  useEffect(() => {
    if (!isFocused) {
      setValidateError('Password doesn’t match guidelines')
    }
    if (!isFocused) {
      setShowValidateBox(false)
    }
  }, [isFocused, value])

  if (hasValidateOnly) {
    return (
      showValidateBox &&
      value?.length > 0 && (
        <PasswordValidateBox
          data={PassWordValidatorData}
          errorLabel={validateError}
          isFocused={!value?.length > 0}
          validationTextColor={validationTextColor}
          iconSize={16}
        />
      )
    )
  }
  return (
    <>
      <TextInput
        eyeIcon={
          <PasswordEyeIcon
            setSecureTextEntry={setSecureTextEntry}
            secureTextEntry={secureTextEntry}
            iconColor={iconColor}
            iconSize={iconSize}
          />
        }
        inputFieldStyle={inputFieldStyle}
        onBlur={() => {
          onBlur()
        }}
        onChangeText={onChangeText}
        onFocus={() => {
          onFocus()
        }}
        password
        placeholder={placeholder}
        placeholderTextColor={placeholderColor || colors.primaryPlaceHolder}
        secureTextEntry={secureTextEntry}
        value={value}
        {...props}
      />
      {showValidateBox && value?.length > 0 && (
        <PasswordValidateBox
          data={PassWordValidatorData}
          errorLabel={validateError}
          isFocused={!value?.length > 0}
          validationTextColor={validationTextColor}
        />
      )}
    </>
  )
}

const PasswordEyeIcon = ({
  secureTextEntry,
  setSecureTextEntry,
  iconColor,
  iconSize,
}) => {
  const { colors } = useTheme()
  const icon = secureTextEntry ? 'EyeOff' : 'Eye'
  return (
    <TouchableOpacity onPress={() => setSecureTextEntry(!secureTextEntry)}>
      <Icon
        name={icon}
        color={iconColor || colors.primaryIconColor}
        width={iconSize || 20}
        height={iconSize || 20}
      />
    </TouchableOpacity>
  )
}

export default PasswordInput
