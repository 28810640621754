import {
  View,
  StyleSheet,
  TouchableOpacity,
  Image,
  Modal,
  useWindowDimensions,
} from 'react-native'
import React, { useEffect, useState } from 'react'
import { Icon } from '@app-hero/native-icons'
import { useTranslation } from 'react-i18next'
import { spacing, useTheme } from '@libs/theme'
import { useIsFocused, useNavigation, useRoute } from '@react-navigation/native'
import { useAtom } from 'jotai'
import { Button, Loader, Text } from '@libs/components'
import { CustomAuth, SecureStore } from '@libs/utils'
import { isMobile, isWeb } from '@libs/utils/src/screenLayout'
import { BlurView } from 'expo-blur'
import {
  menuItems,
  profileDrawerMenu,
  profileDrawerTabs,
  profileDrawerTabsWithoutPathWay,
} from '../../constants'
import {
  brandDetailsAtom,
  cartItemsCount,
  userLanguage,
  userProfile,
  isChatbotVisible,
} from '../../utils/atom'
import { removeScript } from '../../utils/removeScript'
import Drawer from '../Drawer/drawer'
import Notification from '../Notification'
import { FW_CHAT_BOT_URL } from '../../config'
import { userChatBotDestroy } from '../../utils/addScript'
import { findPaddingHorizontal } from '../../utils/findPaddingHorizontal'

const HeaderRight = ({ programIds }) => {
  const { t, i18n } = useTranslation()
  const { colors } = useTheme()
  const navigation = useNavigation()
  const [program] = useAtom(programIds)
  const [userprofile] = useAtom(userProfile)
  const [userSelectedLanguage] = useAtom(userLanguage)
  const [canShowChatbot] = useAtom(isChatbotVisible)
  const [profileOpen, setProfileOpen] = useState(false)
  const [notification, setNotification] = useState(false)
  const [isLoading, setIsLoading] = useState()
  const isFocused = useIsFocused()
  const [cartItemCount] = useAtom(cartItemsCount)
  const { width } = useWindowDimensions()
  const mobile = isMobile(width)
  const isDesktop = isWeb(width)
  const { name } = useRoute()
  const [userDetails] = useAtom(userProfile)
  const [, setBrandDetails] = useAtom(brandDetailsAtom)

  // useEffect
  useEffect(() => {
    if (!isFocused) return
    ;(() => {
      i18n.changeLanguage(userSelectedLanguage.languageCode)
    })()
  }, [isFocused, cartItemCount, userSelectedLanguage])

  const {
    notifications,
    isLoading: isNotificationLoading,
    handleUpdateNotification,
    notificationCount,
    handleEventNavigation,
  } = useNotifications()

  const getImageSource = () => {
    if (userprofile?.selectedImage) {
      return userprofile.selectedImage
    }
    if (userprofile?.profilePictureLocation) {
      return { uri: userprofile?.profilePictureLocation }
    }
  }

  const closeProfileMenu = () => {
    setProfileOpen(false)
    setNotification(false)
  }

  const handleLogout = async () => {
    setIsLoading(true)
    await CustomAuth.logOut()
    await SecureStore.clearAll()

    setBrandDetails({
      colors: {},
      uspDetails: [],
      brand: '',
      brandName: 'AppHero',
      emailStatus: null,
    })

    setTimeout(() => {
      setIsLoading(false)
      setProfileOpen(false)
      const dynamicChatBotUrl = FW_CHAT_BOT_URL(userProfile)
      removeScript(dynamicChatBotUrl)
      window.location.replace('login')
    }, 1000)
  }

  const handleNavigation = (item) => {
    if (item?.action === 'Logout') {
      handleLogout()
    }
    navigation.navigate(item.action)
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  const ProfileMenu = () => (
    <View style={styles.profileMenu(colors)}>
      <View style={styles.arrowUp} />
      {menuItems.map((menuItem, index) => (
        <TouchableOpacity
          key={index}
          onPress={() => {
            if (menuItem.param) {
              navigation.navigate(menuItem.action, {
                routeName: 'profile',
              })
            } else navigation.navigate(menuItem.action)
            setProfileOpen(false)
          }}
        >
          <Text variant="display3" style={styles.menuItem(colors)}>
            {menuItem.label}
          </Text>
          <View style={styles.divider} />
        </TouchableOpacity>
      ))}
      <TouchableOpacity onPress={() => handleLogout()}>
        {isLoading ? (
          <Loader size={20} hasFlexValue={false} colorVariant="blue" />
        ) : (
          <Text variant="display3" style={styles.menuItem(colors)}>
            Logout
          </Text>
        )}
      </TouchableOpacity>
    </View>
  )

  return (
    <View style={styles.headerContainer(isDesktop, width)}>
      {program?.length > 0 && isDesktop ? (
        <Button
          label={`${t('HEADER.COMPARE_PROGRAM')} (${program?.length})`}
          labelColors={colors.onNeutral}
          buttonColor={colors.primaryVariant}
          onPress={() => navigation.navigate('compare-programmes')}
          buttonStyle={{ marginRight: 20 }}
        />
      ) : null}

      {cartItemCount && isDesktop > 0 ? (
        <Button
          label={`${t('HEADER.START_APPLICATION')} (${cartItemCount})`}
          labelColors={colors.white}
          buttonColor={colors.primary}
          onPress={() => {
            navigation.navigate('application-basket')
          }}
          buttonStyle={{ marginRight: 20 }}
        />
      ) : null}

      {!isDesktop && name !== 'my-application' && (
        <View
          style={{
            width: isDesktop ? '4rem' : '3rem',
            justifyContent: 'center',
            flex: 1,
            height: '100%',
          }}
        >
          <TouchableOpacity
            style={[
              styles.buttons(mobile, name),
              { backgroundColor: isDesktop ? colors.primaryVariant : '' },
              { position: isDesktop ? 'relative' : '', overflow: 'visible' },
            ]}
            onPress={() => {
              !isDesktop
                ? navigation.navigate('notification')
                : setNotification(true)
            }}
          >
            <View
              style={{
                backgroundColor: colors?.background,
                width: '2.5rem',
                height: '2.5rem',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 100,
              }}
            >
              <Icon
                name="NotificationBell"
                height={20}
                width={20}
                color="#424242"
              />
            </View>
            {notificationCount > 0 ? (
              <View style={styles.notificationCount}>
                <Text
                  variant="display6"
                  style={{
                    color: '#E5E5E5',
                    fontSize: 12,
                    textAlign: 'center',
                    fontWeight: 500,
                  }}
                >
                  {notificationCount}
                </Text>
              </View>
            ) : null}
          </TouchableOpacity>
        </View>
      )}
      {isDesktop && (
        <View
          style={{
            width: isDesktop ? '4rem' : '3rem',
            justifyContent: 'center',
            flex: 1,
            height: '100%',
          }}
        >
          <TouchableOpacity
            style={[
              styles.buttons(mobile, name),
              { backgroundColor: isDesktop ? colors.primaryVariant : '' },
              { position: isDesktop ? 'relative' : '', overflow: 'visible' },
            ]}
            onPress={() => {
              !isDesktop
                ? navigation.navigate('notification')
                : setNotification(true)
            }}
          >
            <View
              style={{
                backgroundColor: colors?.background,
                width: '2.5rem',
                height: '2.5rem',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 100,
              }}
            >
              <Icon
                name="NotificationBell"
                height={20}
                width={20}
                color="#424242"
              />
            </View>
            {notificationCount > 0 ? (
              <View style={styles.notificationCount}>
                <Text
                  variant="display6"
                  style={{
                    color: '#E5E5E5',
                    fontSize: 12,
                    textAlign: 'center',
                    fontWeight: 500,
                  }}
                >
                  {notificationCount}
                </Text>
              </View>
            ) : null}
          </TouchableOpacity>
        </View>
      )}

      <TouchableOpacity
        style={[
          styles.buttons(mobile, name),
          { backgroundColor: isDesktop ? colors.primaryVariant : '' },
          { position: isDesktop ? 'relative' : '' },
        ]}
        onPress={() => {
          !isDesktop ? '' : setProfileOpen(true)
        }}
      >
        {!isDesktop ? (
          <Drawer
            items={profileDrawerMenu}
            tabs={
              userDetails.canApply
                ? profileDrawerTabs
                : profileDrawerTabsWithoutPathWay
            }
            isProfile={true}
            source={getImageSource()}
            labelColor={colors.draftBlue}
            iconColor={colors.draftBlue}
            onItemSelect={handleNavigation}
            iconSize={20}
            isLoading={isLoading}
            notificationCount={notificationCount}
          />
        ) : userprofile?.selectedImage ||
          userprofile?.profilePictureLocation ? (
          <Image
            source={getImageSource()}
            style={{
              height: '100%',
              width: '100%',
            }}
          />
        ) : (
          <Icon name="User" height={20} width={20} />
        )}
      </TouchableOpacity>

      <Modal
        transparent
        animationType="none"
        visible={notification}
        onRequestClose={closeProfileMenu}
      >
        <BlurView intensity={30} style={styles.blurContainer}>
          <TouchableOpacity
            style={styles.modalOverlay}
            onPress={closeProfileMenu}
            activeOpacity={1}
          >
            {mobile ? (
              ''
            ) : (
              <Notification
                colors={colors}
                navigation={navigation}
                setNotification={setNotification}
                inAppNotifications={
                  notifications !== undefined ? notifications : []
                }
                loading={isNotificationLoading}
                handleUpdateNotification={handleUpdateNotification}
                handleEventNavigation={handleEventNavigation}
              />
            )}
          </TouchableOpacity>
        </BlurView>
      </Modal>

      <Modal
        transparent
        animationType="none"
        visible={profileOpen}
        onRequestClose={closeProfileMenu}
      >
        <BlurView intensity={30} style={styles.blurContainer}>
          <TouchableOpacity
            style={styles.modalOverlay}
            onPress={closeProfileMenu}
            activeOpacity={1}
          >
            {mobile ? '' : <ProfileMenu />}
          </TouchableOpacity>
        </BlurView>
      </Modal>
    </View>
  )
}

const styles = StyleSheet.create({
  headerContainer: (isDesktop, width) => ({
    flexDirection: 'row',
    marginRight: !isDesktop ? 28 : findPaddingHorizontal(width),
    paddingVertical: spacing.spacing6,
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'space-between',
  }),
  buttons: (mobile, name) => ({
    height: mobile ? 40 : 40,
    width: mobile && !name === 'my-application' ? 28 : 40,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  }),
  profileMenu: () => ({
    position: 'absolute',
    top: 30,
    right: 75,
    backgroundColor: 'white',
    borderRadius: 16,
    padding: 28,
    flexDirection: 'column',
  }),
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(2, 21, 71, 0.16)',
  },
  menuItem: (colors) => ({
    color: colors.textPrimary,
    textTransform: 'uppercase',
    fontSize: 14,
    fontWeight: 400,
  }),
  divider: {
    borderWidth: 0.6,
    borderColor: '#E0E0E0',
    borderStyle: 'solid',
    marginVertical: 17,
  },
  arrowUp: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 20,
    borderRightWidth: 20,
    borderBottomWidth: 30,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: 'white',
    position: 'absolute',
    top: -15,
    right: 15,
    zIndex: 0,
  },
  blurContainer: {
    flex: 1,
    textAlign: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    marginTop: 80,
  },
  notificationCount: {
    backgroundColor: '#D72C2C',
    marginLeft: 6,
    width: 21,
    height: 21,
    borderRadius: 21 / 2,
    position: 'absolute',
    top: -8,
    right: -2,
    borderWidth: 1.5,
    textAlign: 'center',
    borderColor: '#E5E5E5',
    padding: 1,
  },
})

export default HeaderRight
