import { useMutation } from '@tanstack/react-query'
import {
  createUserDetails,
  getUserDetails,
  updateUserDetails,
} from '@app-hero/shared/src/api'
import { userProfile } from '@app-hero/shared/src/utils/atom'
import { useAtom } from 'jotai'

export const useUpdateProfile = () => {
  const [userprofile, setUserDetails] = useAtom(userProfile)
  const mutation = useMutation(
    async (data) => {
      const updatedProfile = data?.updatedProfile
      const isInitial = data?.isInitial
      const isLastNameAvailable = data?.isLastNameAvailable || true
      let payload = {
        firstName: updatedProfile?.firstName || updatedProfile?.FirstName,
        lastName: isLastNameAvailable
          ? updatedProfile?.lastName || updatedProfile?.LastName || ''
          : '',
        country: updatedProfile?.country || updatedProfile?.Country,
        countryCode: updatedProfile.countryCode || userprofile.countryCode,
      }

      if (
        Boolean(updatedProfile?.mobile?.mobileNumber) &&
        updatedProfile?.mobile?.mobileNumber !== 'undefined'
      ) {
        payload = {
          ...payload,
          phoneNumber: `${
            !updatedProfile?.mobile?.dialCode ||
            updatedProfile?.mobile?.dialCode === 'undefined'
              ? '+1'
              : updatedProfile?.mobile?.dialCode
          }-${updatedProfile?.mobile?.mobileNumber}`,
        }
      }
      if (isInitial) {
        payload = { ...payload, email: updatedProfile?.email }
      }
      if (updatedProfile.selectedImage) {
        payload = {
          ...payload,
          base64ProfilePicture: updatedProfile.base64Picture,
          fileName: updatedProfile?.fileName,
        }
      }
      if (isInitial) {
        // await createUserDetails(
        //   payload,
        //   userprofile?.email || updatedProfile?.email,
        // )
      } else {
        await updateUserDetails(payload, userprofile?.email)
      }
    },
    {
      onSuccess: async (data, context) => {
        const userDetails = await getUserDetails({
          email: userprofile?.email || context.updatedProfile?.email,
        })
        const newUserDetails = {
          email: userprofile?.email,
          userID: userprofile.userID,
          accessToken: userprofile?.accessToken,
          bucketId: userprofile?.bucketId,
          ...userDetails,
        }
        setUserDetails(newUserDetails)
      },
    },
  )

  return { mutation }
}
