import { t } from 'i18next'
import { Text } from 'react-native'
import { Auth } from 'aws-amplify'
import React, { Suspense, useCallback, useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { ScreenLayout, SecureStore } from '@libs/utils'
import DesktopView from './DesktopView'
import MobileView from './MobileView'
import { brandDetailsAtom, linkedInAtom, userProfile } from '../../utils/atom'
import { createCloudWatchLog } from '../../api'

const LinkedInError = (props) => {
  const isSuccess = props?.code
  const isFailed = props?.error || props?.error_description
  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, MobileView, MobileView),
    [],
  )
  const navigation = useNavigation()

  const isFocused = useIsFocused()
  const [, setAlertBoxDetails] = useAtom(linkedInAtom)
  const [userDetails] = useAtom(userProfile)

  const [brandLogoUrl, setBrandLogoUrl] = useState('')

  useEffect(() => {
    if (isSuccess) {
      ;(async () => {
        if (props.code) {
          const currentUser = await Auth.currentAuthenticatedUser().catch()

          const userData = currentUser?.attributes

          const cloudWatchLoggerPayload = {
            logStream: userData?.email
              ? `apphero-frontend-service/${userData.email}`
              : `apphero-frontend-service/linkedin-user`,
            payload: { typeOfSignin: 'LinkedIn', email: userData.email },
            response: {
              code: props.code,
              message: 'Student successfully registered with LinkedIn',
              email: userData?.email,
            },
            isErrorLog: false,
            errorMessage: props.error_description,
            event: 'SUCCESS_LINKEDIN_USER',
            useCase: 'SIGNIN',
          }

          createCloudWatchLog(cloudWatchLoggerPayload)
          setAlertBoxDetails({})
          navigation.navigate('dashboard')
        }
      })()
    } else if (isFailed) {
      if (props.error_description || props.error) {
        if (
          props.error === 'invalid_request' &&
          props.error_description?.startsWith(
            'PreSignUp failed with error Person Account Not found for this email',
          )
        ) {
          setAlertBoxDetails({
            visible: true,
            message: t('ALERT.INVALID_EMAIL'),
            code: props.error,
          })
        } else if (
          props.error === 'invalid_request' &&
          props.error_description?.startsWith(
            'PreSignUp failed with error Error: Account already exist.',
          )
        ) {
          setAlertBoxDetails({
            visible: true,
            message: t('ALERT.ACCOUNT_ALREADY_EXIST'),
            code: props.error,
          })
        } else {
          setAlertBoxDetails({
            visible: true,
            message: t('AUTH.UNKNOWN_ERROR'),
            code: props.error,
          })
        }

        const cloudWatchLoggerPayload = {
          logStream: userDetails?.email
            ? `apphero-frontend-service/${userDetails.email}`
            : `apphero-frontend-service/linkedin-user`,
          payload: { email: userDetails.email, typeOfSignin: 'LinkedIn' },
          response: {
            error_code: props.error,
            message: props.error_description,
          },
          isErrorLog: true,
          errorMessage: props.error_description,
          event: 'FAILED_LINKEDIN_USER',
          useCase: 'SIGNIN',
        }

        createCloudWatchLog(cloudWatchLoggerPayload)
      }

      navigation.navigate('login')
    }
  }, [isSuccess, isFailed])

  useEffect(() => {
    if (!isFocused) return
    ;(async () => {
      const configData = await SecureStore.getItemAsync('config')
      const config = JSON.parse(configData)?.config
      setBrandLogoUrl(config?.brandLogoUrl)
    })()
  }, [isFocused])

  const [brandDetails] = useAtom(brandDetailsAtom)

  const viewProps = {
    brandDetails,
    brandLogoUrl,
    label: 'Signing in with LinkedIn...',
  }

  return (
    <Suspense fallback={<Text>Loading</Text>}>
      <LayoutView {...viewProps} />
    </Suspense>
  )
}

export default LinkedInError
