export const addScript = (src, callback) => {
  const newScriptUrl = new URL(src)
  const existingScripts = document.querySelectorAll('script[src]')
  existingScripts.forEach((script) => {
    const existingScriptUrl = new URL(script.src)
    if (
      existingScriptUrl.origin === newScriptUrl.origin &&
      existingScriptUrl.pathname === newScriptUrl.pathname
    ) {
      script.remove()
    }
  })

  const script = document.createElement('script')
  script.src = src
  script.async = true
  script.setAttribute('chat', 'true')
  script.setAttribute('widgetId', '48b47732-6c60-4434-a32e-9fd25b19e598')
  document.body.appendChild(script)
  console.log('Script added', script)
}

export const initializeChatbotUser = (userProfile) => {
  const { firstName, lastName, email, externalId, properties } = userProfile

  const checkWidget = () => {
    if (window.fcWidget) {
      window.fcWidget.setExternalId(externalId)
      window.fcWidget.user.setFirstName(firstName)
      window.fcWidget.user.setLastName(lastName)
      window.fcWidget.user.setEmail(email)
      window.fcWidget.user.setProperties(properties)
      if (userProfile?.phoneNumber)
        window.fcWidget.user.setPhone(userProfile?.phoneNumber)
      if (userProfile?.appherotoken) {
        window.fwcrm.on('widget:loaded', () => {
          window.fcWidget.conversation.setConversationProperties({
            cf_appherotoken2: userProfile?.appherotoken,
          })
        })
        window.fcWidget.conversation.setBotVariables({
          appherotoken: userProfile?.appherotoken,
        })
      }
    } else {
      setTimeout(checkWidget, 1000) // Check again after 1000ms
    }
  }
  checkWidget()
}

export const userChatBotDestroy = async () => {
  if (!window.fcWidget) {
    return
  }

  if (window.fcWidget.isInitialized()) {
    try {
      const data = await window.fcWidget?.user?.isExists()
      if (data.data && data.success) {
        await window.fcWidget.user.clear()
      } else {
        window.fcWidget.destroy()
      }
    } catch (error) {
      console.log('Error checking user existence', error)
    }

    // Event listener for user cleared
    window.fcWidget.on('user:cleared', () => {
      window.fcWidget.destroy()
    })

    // Event listener for widget destroyed
    window.fcWidget.on('widget:destroyed', () => {})
  } else {
    console.log('Chatbot is not initialized.')
  }
}
