import React from 'react'
import { Image, StyleSheet, View, useWindowDimensions } from 'react-native'
import { AppheroBeta, AppHeroLogoLite } from '@apphero/assets'
import { Icon } from '@app-hero/native-icons'
import LottieView from 'react-native-web-lottie'
import LinearGradient from 'react-native-linear-gradient'
import { isWeb } from '@libs/utils/src/screenLayout'
import { useTheme } from '@libs/theme'
import { Text } from '@libs/components'

const MobileView = (props) => {
  const { style, hasFlexValue = true, label, subText } = props
  const { colors } = useTheme()
  const { width } = useWindowDimensions()
  const isDesktop = isWeb(width)

  const brandLogoUrl = props?.brandLogoUrl

  const brandDetails = props?.brandDetails

  const LogoUrl = `${brandLogoUrl}/${brandDetails?.brand?.replaceAll(
    ' ',
    '_',
  )}.png`

  return isDesktop ? (
    <View
      style={[
        {
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: colors?.backgroundSecondary,
        },
        hasFlexValue ? { flex: 1 } : {},
        style,
      ]}
    >
      <View
        style={[
          styles.leftHeader,
          { flexDirection: 'row', alignItems: 'center' },
        ]}
      >
        <Image
          source={AppheroBeta}
          style={{ height: 38, width: 146 }}
          resizeMode="contain"
        />
        {brandDetails.brand && brandLogoUrl && (
          <>
            <View
              style={{
                height: '1.15rem',
                width: 2,
                backgroundColor: colors.neutral,
                marginHorizontal: '0.75rem',
              }}
            />
            <Image
              source={LogoUrl}
              style={{ height: 60, width: 146 }}
              resizeMode="contain"
              alt={brandDetails.brand}
            />
          </>
        )}
      </View>
      <View
        style={[
          styles.cardContainer(colors),
          {
            maxHeight: '30rem',
            width: '30rem',
          },
        ]}
      >
        <Icon
          name="AuthLoading"
          style={{
            height: 152,
            width: 132,
            marginTop: '2.5rem',
          }}
        />
        <Text
          variant="heading3"
          style={{ marginTop: '1.5rem' }}
          color={colors.titleColor}
        >
          {label}
        </Text>
        {subText && (
          <Text
            variant="display4"
            style={{
              marginLeft: 30,
              marginRight: 30,
              marginTop: 12,
              textAlign: 'center',
            }}
            color={colors.titleColor}
          >
            {subText}
          </Text>
        )}
        <View style={styles.animationContainer}>
          <LottieView
            source={require('../../../../../libs/components/src/Loader/loadingAnimation.json')}
            autoPlay
            loop
            speed={0.8}
          />
        </View>
      </View>
    </View>
  ) : (
    <LinearGradient
      style={[
        {
          justifyContent: 'center',
          backgroundColor: colors?.primaryBackground,
          paddingLeft: 20,
          paddingRight: 20,

          minHeight: '100vh',
          paddingBottom: 80,
        },
        style,
      ]}
      start={{ x: 0, y: 0 }}
      end={{ x: -1, y: -1 }}
      colors={['#2563EB', '#18181B']}
      locations={[0.2, 1.3]}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Image
          source={AppHeroLogoLite}
          style={{ height: 38, width: 146 }}
          resizeMode="contain"
        />
        {brandDetails.brand && brandLogoUrl && (
          <>
            <View
              style={{
                height: '1.15rem',
                width: 2,
                backgroundColor: 'rgba(255, 255, 255, 0.4)',
                marginHorizontal: '0.75rem',
              }}
            />
            <Image
              source={`${brandLogoUrl}/${brandDetails?.brand?.replaceAll(
                ' ',
                '_',
              )}_LIGHT.png`}
              style={{ height: 60, width: 146 }}
              resizeMode="contain"
              alt={brandDetails.brand}
            />
          </>
        )}
      </View>
      <View
        style={[
          styles.cardContainer(colors),
          { width: 327, marginHorizontal: 'auto' },
        ]}
      >
        <Icon
          name="AuthLoading"
          style={{
            height: 152,
            width: 132,
            marginTop: 92,
          }}
        />
        <Text
          variant="heading3"
          style={{ paddingTop: 32, paddingBottom: 24, width: '70%' }}
          numberOfLines={2}
          textAlign="center"
        >
          {label}
        </Text>
        {subText && (
          <Text
            variant="display4"
            style={{ width: '70%', marginTop: 12 }}
            numberOfLines={2}
            textAlign="center"
          >
            {subText}
          </Text>
        )}
        <View style={styles.animationContainer}>
          <LottieView
            source={require('../../../../../libs/components/src/Loader/loadingAnimation.json')}
            autoPlay
            loop
            speed={0.8}
          />
        </View>
      </View>
    </LinearGradient>
  )
}
const styles = StyleSheet.create({
  leftHeader: {
    position: 'absolute',
    zIndex: 20,
    top: '1.25rem',
    left: '5.625rem',
  },
  animationContainer: {
    height: 123,
    width: 170,
  },
  cardContainer: (color) => ({
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: color?.card,
    borderRadius: 16,
    shadowColor: 'rgba(0, 0, 0, 0.1)', // Black shadow
    shadowOffset: { width: 0, height: 6 }, // Adjust shadow offset
    shadowOpacity: 0.25, // Adjust opacity (0-1 range)
    shadowRadius: 8, // Blur radius of the shadow
    elevation: 5,
  }),
})
export default MobileView
