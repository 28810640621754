import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { Auth } from 'aws-amplify'
import LottieView from 'react-native-web-lottie'
import { useNavigation, useRoute } from '@react-navigation/native'
import { removeScript } from '../utils/removeScript'
import { FW_CHAT_BOT_URL } from '../config'
import { userProfile } from '../utils/atom'

const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null)
  const navigation = useNavigation()
  const route = useRoute()
  const [userDetails] = useState(userProfile)

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        await Auth.currentAuthenticatedUser()
        setIsAuthenticated(true)
      } catch {
        setIsAuthenticated(false)
        setTimeout(() => {
          removeScript(FW_CHAT_BOT_URL(userDetails))
          window.location.replace('login')
        }, 1000)
        navigation.navigate('login', { redirect: route.path })
      }
    }

    checkAuthStatus()
  }, [navigation, isAuthenticated, route])

  if (isAuthenticated === null) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <View
          style={{
            height: 123,
            width: 170,
          }}
        >
          <LottieView
            source={require('../../../../libs/components/src/Loader/loadingAnimation.json')}
            autoPlay
            loop
            speed={0.8}
          />
        </View>
      </View>
    )
  }

  return isAuthenticated ? children : null
}

export default ProtectedRoute
