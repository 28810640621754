import React, { useEffect, useState } from 'react'
import {
  View,
  StyleSheet,
  Image,
  TouchableOpacity,
  Modal,
  useWindowDimensions,
} from 'react-native'
import { useTranslation } from 'react-i18next'
import { Text, Button } from '@libs/components'
import { AppheroBeta } from '@apphero/assets'
import { spacing, useTheme } from '@libs/theme'
import { useIsFocused, useNavigation, useRoute } from '@react-navigation/native'
import { useAtom } from 'jotai'
import { isMobile, isWeb } from '@libs/utils/src/screenLayout'
import { SecureStore, CustomAuth } from '@libs/utils'
import { useNotifications } from '@app-hero/shared-hooks'
import { Icon } from '@app-hero/native-icons'
import { useQuery } from '@tanstack/react-query'
import {
  brandDetailsAtom,
  userProfile,
  cartItemsCount,
  userLanguage,
  isChatbotVisible,
} from '../../utils/atom'
import Drawer from '../Drawer/drawer'
import Notification from '../Notification'
import { removeScript } from '../../utils/removeScript'
import { FW_CHAT_BOT_URL } from '../../config'
import {
  menuItems,
  profileDrawerMenu,
  profileDrawerTabs,
  profileDrawerTabsWithoutPathWay,
} from '../../constants'
import { getTasksByEmail } from '../../api'
import { findPaddingHorizontal } from '../../utils/findPaddingHorizontal'
import { getTotalActionRequiredCount } from '../../utils/getTotalActionRequiredCount'
import LoadingSpinner from '../dynamicContentPanel/customLoader'

// Ensure all atom definitions are at the top level

const HeaderLeft = ({ programId, innerWidth }) => {
  const { t, i18n } = useTranslation()
  const { colors } = useTheme()
  const navigation = useNavigation()
  const route = useRoute()
  const [userprofile] = useAtom(userProfile)
  const { width } = useWindowDimensions()
  const mobile = isMobile(width)
  const isDesktop = isWeb(width)
  const isFocused = useIsFocused()

  const [brandDetails, setBrandDetails] = useAtom(brandDetailsAtom)
  const [cartItemCount] = useAtom(cartItemsCount)
  const [userSelectedLanguage] = useAtom(userLanguage)
  const [canShowChatbot, setCanShowChatbot] = useAtom(isChatbotVisible)
  const [brandLogoUrl, setBrandLogoUrl] = useState('')
  const [profileOpen, setProfileOpen] = useState(false)
  const [notification, setNotification] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const {
    notifications,
    isLoading: isNotificationLoading,
    handleUpdateNotification,
    notificationCount,
    handleEventNavigation,
  } = useNotifications()

  useEffect(() => {
    if (!isFocused) return
    ;(async () => {
      const configData = await SecureStore.getItemAsync('config')
      const config = JSON.parse(configData)?.config
      setBrandLogoUrl(config?.brandLogoUrl)
      i18n.changeLanguage(userSelectedLanguage.languageCode)
    })()
  }, [isFocused, cartItemCount, userSelectedLanguage, i18n])

  const fetchTotalActionRequiredCount = async () => {
    const response = await getTasksByEmail({ email: userprofile?.email })
    const totalCount = getTotalActionRequiredCount(response?.response)
    return totalCount
  }
  const { data: totalActionRequiredCount } = useQuery({
    queryKey: ['getTotalActionRequiredCount'],
    queryFn: fetchTotalActionRequiredCount,
    enabled: isFocused && !!userprofile?.email,
    initialData: [],
  })

  const getImageSource = () => {
    if (userprofile?.selectedImage) {
      return userprofile.selectedImage
    }
    if (userprofile?.profilePictureLocation) {
      return { uri: userprofile?.profilePictureLocation }
    }
  }

  const closeProfileMenu = () => {
    setProfileOpen(false)
    setNotification(false)
  }

  const handleLogout = async () => {
    setIsLoading(true)
    await CustomAuth.logOut()
    await SecureStore.clearAll()

    setBrandDetails({
      colors: {},
      uspDetails: [],
      brand: '',
      brandName: 'AppHero',
      emailStatus: null,
    })

    setCanShowChatbot(false)

    setTimeout(() => {
      setIsLoading(false)
      setProfileOpen(false)
      const dynamicChatBotUrl = FW_CHAT_BOT_URL(userProfile)
      removeScript(dynamicChatBotUrl)
      window.location.replace('login')
    }, 1000)
  }

  const handleNavigation = (item) => {
    if (item?.action === 'logout') {
      handleLogout()
    } else {
      navigation.navigate(item.action)
    }
  }

  const ProfileMenu = () => (
    <View style={styles.profileMenu(colors)}>
      <View style={styles.arrowUp} />
      {menuItems.map((menuItem, index) => (
        <TouchableOpacity
          key={index}
          onPress={() => {
            if (menuItem.param) {
              navigation.navigate(menuItem.action, {
                routeName: 'profile',
              })
            } else navigation.navigate(menuItem.action)
            setProfileOpen(false)
          }}
        >
          <Text variant="display3" style={styles.menuItem(colors)}>
            {menuItem.label}
          </Text>
          <View style={styles.divider} />
        </TouchableOpacity>
      ))}
      <TouchableOpacity onPress={handleLogout}>
        {isLoading ? (
          <LoadingSpinner size={20} />
        ) : (
          <Text variant="display3" style={styles.menuItem(colors)}>
            Logout
          </Text>
        )}
      </TouchableOpacity>
    </View>
  )

  return (
    <View style={styles.headerContainer(isDesktop, width, innerWidth)}>
      <View style={styles.leftSection}>
        <TouchableOpacity onPress={() => navigation.navigate('dashboard')}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Image
              source={AppheroBeta}
              style={{ height: 38, width: mobile ? 116 : 146 }}
              resizeMode="contain"
            />
            {brandDetails.brand && brandLogoUrl && (
              <>
                <View style={styles.brandDivider(colors)} />
                <Image
                  source={`${brandLogoUrl}/${brandDetails.brand?.replaceAll(
                    ' ',
                    '_',
                  )}.png`}
                  style={{ height: 60, width: 146 }}
                  resizeMode="contain"
                  alt={brandDetails.brand}
                />
              </>
            )}
          </View>
        </TouchableOpacity>
      </View>
      <View>
        {isDesktop && (
          <View style={styles.navLinks}>
            {['dashboard', 'my-application', 'action-required'].map(
              (item, index) => (
                <TouchableOpacity
                  key={item}
                  onPress={() => navigation.navigate(item)}
                  style={styles.navItem}
                >
                  <Text
                    color={
                      route?.name === item ? colors.draftBlue : colors.onNeutral
                    }
                    style={{ fontWeight: route?.name === item ? 700 : 400 }}
                    variant="body2"
                  >
                    {t(`HEADER.${item.toUpperCase().replace('-', '_')}`)}
                    {index === 2 && totalActionRequiredCount > 0 ? (
                      <View style={styles.actionRequiredCount}>
                        <Text
                          variant="display6"
                          style={styles.notificationCountText}
                        >
                          {totalActionRequiredCount}
                        </Text>
                      </View>
                    ) : null}
                  </Text>
                  {route?.name === item && (
                    <View style={styles.activeIndicator(colors)} />
                  )}
                </TouchableOpacity>
              ),
            )}
          </View>
        )}
      </View>
      <View style={styles.rightSection}>
        {programId?.length > 0 && isDesktop && (
          <Button
            label={`${t('HEADER.COMPARE_programId')} (${programId?.length})`}
            labelColors={colors.onNeutral}
            buttonColor={colors.primaryVariant}
            onPress={() => navigation.navigate('compare-programIdmes')}
            buttonStyle={{ marginRight: 20 }}
          />
        )}

        {cartItemCount > 0 && isDesktop && (
          <Button
            label={`${t('HEADER.START_APPLICATION')} (${cartItemCount})`}
            labelColors={colors.white}
            buttonColor={colors.primary}
            onPress={() => navigation.navigate('application-basket')}
            buttonStyle={{ marginRight: 20 }}
          />
        )}

        {isDesktop ? (
          <TouchableOpacity
            style={styles.iconButton(colors, isDesktop)}
            onPress={() =>
              !isDesktop
                ? navigation.navigate('notification')
                : setNotification(true)
            }
          >
            <View style={styles.iconContainer(colors)}>
              <Icon
                name="NotificationBell"
                height={20}
                width={20}
                color="#424242"
              />
            </View>
            {notificationCount > 0 && (
              <View style={styles.notificationCount}>
                <Text variant="display6" style={styles.notificationCountText}>
                  {notificationCount}
                </Text>
              </View>
            )}
          </TouchableOpacity>
        ) : null}

        <TouchableOpacity
          style={[
            styles.buttons(mobile, route?.name),
            { backgroundColor: isDesktop ? colors.primaryVariant : '' },
            { position: isDesktop ? 'relative' : '' },
          ]}
          onPress={() => {
            !isDesktop ? '' : setProfileOpen(true)
          }}
        >
          {!isDesktop ? (
            <Drawer
              items={profileDrawerMenu}
              tabs={
                userprofile.canApply
                  ? profileDrawerTabs
                  : profileDrawerTabsWithoutPathWay
              }
              isProfile={true}
              source={getImageSource()}
              labelColor={colors.draftBlue}
              iconColor={colors.draftBlue}
              onItemSelect={handleNavigation}
              iconSize={20}
              isLoading={isLoading}
              notificationCount={notificationCount}
              actionRequiredCount={totalActionRequiredCount}
            />
          ) : userprofile?.selectedImage ||
            userprofile?.profilePictureLocation ? (
            <Image
              source={getImageSource()}
              style={{
                height: '100%',
                width: '100%',
              }}
            />
          ) : (
            <Icon name="User" height={20} width={20} />
          )}
        </TouchableOpacity>

        <Modal
          transparent
          animationType="none"
          visible={notification}
          onRequestClose={closeProfileMenu}
        >
          <TouchableOpacity
            style={[styles.modalOverlay, styles.blurContainer]}
            onPress={closeProfileMenu}
            activeOpacity={1}
          >
            {!mobile && (
              <Notification
                colors={colors}
                navigation={navigation}
                setNotification={setNotification}
                inAppNotifications={notifications || []}
                loading={isNotificationLoading}
                handleUpdateNotification={handleUpdateNotification}
                handleEventNavigation={handleEventNavigation}
                paddingHorizontal={findPaddingHorizontal(width)}
              />
            )}
          </TouchableOpacity>
        </Modal>

        <Modal
          transparent
          animationType="none"
          visible={profileOpen}
          onRequestClose={closeProfileMenu}
        >
          <TouchableOpacity
            style={[styles.modalOverlay, styles.blurContainer]}
            onPress={closeProfileMenu}
            activeOpacity={1}
          >
            {!mobile && <ProfileMenu />}
          </TouchableOpacity>
        </Modal>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  headerContainer: (isDesktop, width) => ({
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: !isDesktop ? 28 : findPaddingHorizontal(width),
    paddingVertical: spacing.spacing6,
    width,
    backgroundColor: '#fff',
  }),
  leftSection: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  rightSection: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 22,
  },
  brandDivider: (colors) => ({
    height: '1.15rem',
    width: 2,
    backgroundColor: colors.neutral,
    marginHorizontal: '0.75rem',
  }),
  navLinks: {
    flexDirection: 'row',
  },
  navItem: {
    marginHorizontal: 23,
    position: 'relative',
  },
  activeIndicator: (colors) => ({
    position: 'absolute',
    bottom: -30,
    left: 0,
    width: '100%',
    borderRadius: 12,
    height: 3,
    backgroundColor: colors.draftBlue,
  }),
  iconButton: (colors, isDesktop) => ({
    height: 40,
    width: 40,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: isDesktop ? colors.primaryVariant : 'transparent',
    marginLeft: 10,
  }),
  iconContainer: (colors) => ({
    backgroundColor: colors?.background,
    width: '2.5rem',
    height: '2.5rem',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 100,
  }),
  notificationCount: {
    backgroundColor: '#D72C2C',
    width: 21,
    height: 21,
    borderRadius: 10.5,
    position: 'absolute',
    top: -8,
    right: -2,
    borderWidth: 1.5,
    borderColor: '#E5E5E5',
    justifyContent: 'center',
    alignItems: 'center',
  },
  notificationCountText: {
    color: '#E5E5E5',
    fontSize: 12,
    textAlign: 'center',
    fontWeight: '500',
  },
  profileImage: {
    height: '100%',
    width: '100%',
  },
  profileMenu: () => ({
    position: 'absolute',
    top: 30,
    right: findPaddingHorizontal(window.innerWidth) - 15,
    backgroundColor: 'white',
    borderRadius: 16,
    paddingHorizontal: 24,
    paddingVertical: 32,
    flexDirection: 'column',
    shadowColor: 'black', // Black shadow
    shadowOffset: { width: 0, height: 6 }, // Adjust shadow offset
    shadowOpacity: 0.25, // Adjust opacity (0-1 range)
    shadowRadius: 8, // Blur radius of the shadow
    elevation: 5,
  }),
  menuItem: (colors) => ({
    color: colors.textPrimary,
    fontSize: 14,
    fontWeight: 400,
  }),
  divider: {
    borderWidth: 0.6,
    borderColor: '#E0E0E0',
    borderStyle: 'solid',
    marginVertical: 17,
  },
  buttons: (mobile, name) => ({
    height: mobile ? 40 : 40,
    width: mobile && !name === 'my-application' ? 28 : 40,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  }),
  arrowUp: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 20,
    borderRightWidth: 20,
    borderBottomWidth: 30,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: 'white',
    position: 'absolute',
    top: -15,
    right: 15,
    zIndex: 0,
  },
  blurContainer: {
    flex: 1,
    textAlign: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    marginTop: 80,
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionRequiredCount: {
    backgroundColor: '#D72C2C',
    width: 20,
    height: 20,
    borderRadius: 10,
    position: 'absolute',
    borderWidth: 1.5,
    borderColor: '#E5E5E5',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: -1,
    marginLeft: 4,
  },
})
export default HeaderLeft
