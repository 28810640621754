import { View, StyleSheet, Modal, TouchableOpacity, Image } from 'react-native'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, DropDown, FilePicker, Loader, Text } from '@libs/components'

import { Icon } from '@app-hero/native-icons'
import {
  PdfImage,
  PngImage,
  JpegImage,
  JpgImage,
  MiscImage,
} from '@apphero/assets'
import { useTheme } from '@libs/theme'
import { BlurView } from 'expo-blur'
import FileUploadModalMobile from './index.mobile'
import { AlertBox } from '../actionRequired/alertConatiner'

const FileUploadModal = ({
  title = '',
  visible = false,
  toggleDropdown = () => {},
  handleSave = async () => {},
  handleClose = () => {},
  dropDownRef,
  dropDownPosition = {},
  dropdownWidth = '',
  documentType = [],
  selectedDropDownValue,
  taskId,
  setModalVisible,
}) => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const [file, setFile] = useState({})
  const [error, setError] = useState()
  const [selectedDocumentType, setSelectedDocumentType] = useState({})
  const [isFileSaving, setIsFileSaving] = useState()

  const selectedDropDownItem = documentType?.find(
    (item) => item.label === selectedDropDownValue,
  )

  useEffect(() => {
    if (selectedDropDownItem) {
      setSelectedDocumentType(selectedDropDownItem)
    }
  }, [selectedDropDownItem, file])

  const handleSaveDocument = async () => {
    setIsFileSaving(true)
    await handleSave({
      ...file,
      documentType: selectedDocumentType?.label,
      taskId,
    })
    setIsFileSaving(false)

    handleClose()
    setSelectedDocumentType({})
    setFile({})
  }

  const canCTADisable = () =>
    Object.keys(selectedDocumentType).length === 0 ||
    Object.keys(file).length === 0 ||
    !!error
  const getFileTypeImage = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase()
    switch (extension) {
      case 'pdf':
        return PdfImage
      case 'png':
        return PngImage
      case 'jpg':
        return JpgImage
      case 'jpeg':
        return JpegImage
      default:
        return MiscImage
    }
  }

  if (window.innerWidth < 650) {
    return (
      <FileUploadModalMobile
        title={title}
        visible={visible}
        toggleDropdown={toggleDropdown}
        handleSave={handleSave}
        handleClose={handleClose}
        dropDownPosition={dropDownPosition}
        dropDownRef={dropDownRef}
        dropdownWidth={dropdownWidth}
        documentType={documentType}
        selectedDropDownValue={selectedDropDownValue}
        taskId={taskId}
        setModalVisible={setModalVisible}
      />
    )
  }

  return (
    <Modal
      visible={visible}
      onBackdropPress={() => {
        handleClose()
        setSelectedDocumentType({})
        setFile({})
      }}
      onRequestClose={() => {
        handleClose()
        setSelectedDocumentType({})
        setFile({})
      }}
      transparent
      animationType="none"
      style={{ flex: 1, zIndex: 200 }}
    >
      <BlurView intensity={80} style={styles.overlay}>
        <View style={[styles.container, styles.modalOverlay]}>
          <View style={styles.card}>
            <View style={[styles.header]}>
              <TouchableOpacity
                style={{
                  position: 'absolute',
                  right: window.innerWidth <= 440 ? -0 : -20,
                  top: window.innerWidth <= 440 ? -5 : -15,
                }}
                onPress={() => {
                  handleClose()
                  setSelectedDocumentType({})
                  setFile({})
                }}
              >
                <Icon name="Close" height={23} width={23} />
              </TouchableOpacity>
              <Text
                style={{
                  paddingVertical: 20,
                  fontSize: 24,
                  fontWeight: '700',
                  color: colors.neutral,
                }}
                placeHolder="Text"
              >
                {title}
              </Text>
            </View>
            <View>
              <Text variant="display4" color={colors.neutral}>
                {t('FILE_UPLOAD.DOCUMENT_TYPE')}{' '}
                <Text variant="display4" color={colors.onAlert}>
                  *
                </Text>
              </Text>

              <View ref={dropDownRef}>
                <DropDown
                  items={documentType}
                  toggleDropdown={toggleDropdown}
                  position={dropDownPosition}
                  dropdownWidth={dropdownWidth}
                  onPress={(selectedOption) =>
                    setSelectedDocumentType(selectedOption)
                  }
                  label={t('DROP_DOWN.LABEL_SELECT')}
                  dropdownHeight={190}
                  style={{
                    margin: 0,
                    marginTop: 8,
                    marginBottom: 20,
                  }}
                  placeHolderColor={colors.fieldBorder}
                  value={selectedDropDownItem}
                  disable={selectedDropDownItem}
                />
              </View>
            </View>
            <View>
              <Text
                variant="display4"
                style={{
                  flexWrap: 'wrap',
                  flex: 1,
                }}
                color={colors.neutral}
              >
                {t('FILE_UPLOAD.ATTACH_DOCUMENT')}{' '}
                <Text variant="display4" color={colors.onAlert}>
                  *
                </Text>
              </Text>
              {Object.keys(file).length > 0 ? (
                <View>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      borderColor: colors.primaryBackground,
                      borderWidth: 2,
                      padding: 20,
                      marginVertical: 15,
                      borderRadius: 15,
                      flexWrap: 'wrap',
                    }}
                  >
                    <View
                      style={{
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        flex: 1,
                      }}
                    >
                      <Image
                        source={getFileTypeImage(file.name)}
                        style={{ height: 15, width: 15 }}
                      />
                      <Text
                        variant="body2"
                        style={{ marginHorizontal: 10, maxWidth: '70%' }}
                      >
                        {file.name}
                      </Text>
                    </View>
                    <TouchableOpacity
                      onPress={() => {
                        setFile({})
                        setError('')
                      }}
                    >
                      <Icon name="Close" height={15} width={15} />
                    </TouchableOpacity>
                  </View>
                </View>
              ) : (
                <FilePicker
                  style={{
                    marginBottom: 20,
                  }}
                  handleFileSelect={({ fileJson, error, file }) => {
                    if (error) {
                      setError(error)
                    }
                    setFile({ ...fileJson, file })
                  }}
                />
              )}
            </View>
            <View style={{ flex: 1, marginBottom: 20 }}>
              <AlertBox
                iconColor={colors.alertPrimary}
                iconSize={20}
                iconName="AlertCircle"
                style={{
                  flexDirection: 'row',
                  gap: 8,
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <View style={{ flex: 1, flexWrap: 'wrap', width: '80%' }}>
                  <Text variant="display4" color={colors.alertPrimary}>
                    <b>{t('FILE_UPLOAD.NOTE')}</b>
                    {t('FILE_UPLOAD.ALERT')}
                  </Text>
                </View>
              </AlertBox>
            </View>
            {error ? (
              <Text
                variant="display4"
                color={colors.onAlert}
                style={{ marginBottom: 24 }}
              >
                {error}
              </Text>
            ) : null}
            <View style={styles.buttonContainer}>
              {isFileSaving ? (
                <Loader size={20} />
              ) : (
                <View
                  style={{
                    flex: 1,
                    flexDirection: 'row',
                    gap: 16,
                    flexWrap: 'wrap',
                  }}
                >
                  <Button
                    label={t('BUTTON.SAVE')}
                    buttonColor={colors.primary}
                    onPress={() => handleSaveDocument()}
                    disable={canCTADisable()}
                    labelColors="#FFFF"
                    labelvariant="heading5"
                    buttonStyle={{
                      flex: 1,
                      borderRadius: 4,
                      paddingHorizontal: 83.5,
                      paddingVertical: 10,
                    }}
                    labelStyle={{
                      textTransform: 'uppercase',
                      fontWeight: '700',
                    }}
                  />
                  <Button
                    label={t('BUTTON.CANCEL')}
                    buttonColor="#B6CFF3"
                    onPress={() => {
                      handleClose()
                      setSelectedDocumentType({})
                      setFile({})
                      setError('')
                    }}
                    appearance="outline"
                    labelColors={colors.primary}
                    buttonStyle={{
                      flex: 1,
                      borderRadius: 4,
                      paddingHorizontal: 83.5,
                      paddingVertical: 10,
                    }}
                    labelStyle={{ textTransform: 'uppercase' }}
                  />
                </View>
              )}
            </View>
          </View>
        </View>
      </BlurView>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#000000CC',
    width: '100%',
    paddingHorizontal: window.innerWidth <= 440 ? 24 : 40,
    justifyContent: 'center',
  },
  card: {
    alignSelf: 'center',
    backgroundColor: '#ffffff',
    paddingHorizontal: window.innerWidth <= 440 ? 20 : 40,
    paddingVertical: window.innerWidth <= 440 ? 16 : 35,
    justifyContent: 'center',
    maxWidth: 502,
    width: '100%',
    borderRadius: 15,
  },
  header: {
    position: 'relative',
  },
  buttonContainer: {
    flexDirection: 'row',
  },
  overlay: {
    flex: 1,
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(11, 92, 215, 0.2)',
  },
})

export default FileUploadModal
